import moment from 'moment';

const services = {};

services.mixins = {
  filters: {
    moneda(value) {
      return value.toLocaleString('ARS', { style: 'currency', currency: 'ARS' });
    },
    fecha(value) {
      return moment(value).format('DD-MM-YYYY');
    },
  },
  methods: {
    filtarPorFecha(fechainicio,fechafin,value) {
      const inicio = moment(fechainicio).format('YYYY-MM-DD');
      const fin = moment(fechafin).format('YYYY-MM-DD');
      const fecha = moment(value).format('YYYY-MM-DD');
      return moment(fecha).isBetween(inicio, fin, null, '[]');
    },
    recargarPagina() {
      location.reload();
    }
  }
};

services.fechaActual = moment().format('YYYY-MM-DD');

services.fechaRestar = function fechaRestar(cantidad, unidad) {
  return moment().subtract(cantidad, unidad).format('YYYY-MM-DD');
};

/*
 * completa con ceros a la izquierda de un numero
 */
services.cerosFill = function (number, width) {
  var numberOutput = Math.abs(number); /* Valor absoluto del número */
  var length = number.toString().length; /* Largo del número */ 
  var zero = "0"; /* String de cero */  
  
  if (width <= length) {
      if (number < 0) {
           return ("-" + numberOutput.toString()); 
      } else {
           return numberOutput.toString(); 
      }
  } else {
      if (number < 0) {
          return ("-" + (zero.repeat(width - length)) + numberOutput.toString()); 
      } else {
          return ((zero.repeat(width - length)) + numberOutput.toString()); 
      }
  }
}


export default services;