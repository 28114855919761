<template>
  <v-layout row wrap id="ListarClientes">
    <v-flex lg12 v-if="notificacion.mensaje">
      <alert
        v-bind:tipo="notificacion.tipo"
        v-bind:mensaje="notificacion.mensaje"
      ></alert>
    </v-flex>
    <template v-if="!notificacion.mensaje">
      <template v-if="isAdmin">
        <template v-if="(Cliente.cli_codigo > 0)">
          <v-flex xs12 sm6>
            <h3>
              {{ titulo }}: {{ Cliente.cli_razsoc }} 
              <v-btn
                class="mx-2"
                fab
                dark
                small
                color="alert"
                @click="quitarCliente"
              >
                <v-icon dark>clear</v-icon>
              </v-btn>
            </h3>
          </v-flex>
        </template>
        <template v-else>
          <v-flex xs12 sm6>
            <template>
              <v-autocomplete
                v-model="Cliente"
                :items="Clientes"
                item-text="cli_razsoc"
                item-value="cli_codigo"
                @change="SelectCliente"
                :loading="isLoading"
                label="Seleccione un cliente para continuar"
              ></v-autocomplete>
            </template>
          </v-flex>
        </template>
      </template>
      <template v-else>
        <v-flex xs12>
          <h3>{{ titulo }}: {{ Cliente.cli_razsoc }}</h3>
        </v-flex>
      </template>
    </template>
  </v-layout>
</template>

<script>
import userService from "@/services/UserServices";
import clienteService from "@/services/ClienteServices";
import alert from "@/components/alert/alert.vue";
export default {
  name: "ListarClientes",
  components: { alert },
  props: {
    titulo: {
      require: true,
      default: "sección"
    },
    busmodulo: {
      require: false,
      default: "CtaCte"
    }
  },
  computed: {
    isAdmin() {
      return userService.IsAdmin();
    }
  },
  data() {
    return {
      Cliente: clienteService.current(),
      Clientes: [],
      isLoading: true,
      notificacion: { mensaje: "", tipo: "error" }
    };
  },
  created() {
    if (this.isAdmin) {
      if(this.Cliente.cli_codigo < 0){
        this.getClientes();
      }
    }
  },
  methods: {
    SelectCliente() {
      clienteService.setCliente(this.Cliente)
      .then(
        // eslint-disable-next-line 
        res => {
        this.Cliente = clienteService.current()
      });
      this.$bus.$emit("SelectorClienteSetCliente_"+this.busmodulo);
    },
    quitarCliente(){
      clienteService.unsetCliente();
      this.Cliente = clienteService.current();
      this.getClientes();
      //console.log("SelectorClienteSetCliente_"+this.busmodulo);
      this.$bus.$emit("SelectorClienteSetCliente_"+this.busmodulo);
    },
    getClientes() {
      clienteService
        .getAll()
        .then(res => {
          this.Clientes = res;
        })
        .catch(err => {
          this.notificacion.mensaje =
            "Ocurrio un error al intentar recuperar los registros.";
          if (
            err.response &&
            err.response.data.hasOwnProperty("errorMessage")
          ) {
            this.notificacion.mensaje = err.response.data.errorMessage;
          }
          return [];
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  }
};
</script>
